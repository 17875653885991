export type IResult<T, E = Error> =
  | { ok: true, value: T }
  | { ok: false, error: E }

export class Result {
  static success<T>(data?: T): IResult<T> {
    let value = null as T

    if (data) {
      value = data
    }

    return {
      ok: true,
      value
    }
  }

  static failure<T, E>(error: E): IResult<T, E> {
    return {
      ok: false,
      error
    }
  }
}
