import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EmailService } from "./core/controllers/email.controller";
import logo from './assets/logo.png';
import './index.css';
import { PostDecryptEmailDTOS } from "./core/models/post/decrypt.email.dtos";
import { LoadingComponent } from "./core/components/loading";

const emailService = new EmailService();

function App() {
  const [email, setEmail] = useState<PostDecryptEmailDTOS | null>(null);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [termsAlreadyAccepted, setTermsAlreadyAccepted] = useState<boolean>(false);
  const [language, setLanguage] = useState<'en' | 'de'>('en');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailNotFound, setEmailNotFound] = useState<boolean>(false); // Novo estado
  const [userType, setUserType] = useState<'user' | 'manager' | null>(null);

  const location = useLocation();

  useEffect(() => {
    const encryptedEmailFromUrl = location.pathname.substring(1);
    if (encryptedEmailFromUrl) {
      setEmail({ encryptedEmail: encryptedEmailFromUrl });
    }

    async function getData() {
      try {
        const checkAcceptedTermsResult = await emailService.checkEmail({ encryptedEmail: encryptedEmailFromUrl });

        if (checkAcceptedTermsResult.ok && checkAcceptedTermsResult.value) {
          if (checkAcceptedTermsResult.value.exists) {
            setTermsAlreadyAccepted(true);
          }
          const type = checkAcceptedTermsResult.value.type as unknown as string;
          setUserType((type === 'user' || type === 'manager') ? type : null);
        } else {
          console.log("Error on request check email.");
          setEmailNotFound(true);
        }
      } catch (error) {
        console.error(error);
        setEmailNotFound(true);
      }
    }

    getData();
  }, [location]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptedTerms(event.target.checked);
  };

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'de' : 'en');
  };

  const handleSubmit = async () => {
    if (email) {
      setIsLoading(true); // Começa o carregamento
      try {
        const data = await emailService.acceptTerms(email);
        if (data.ok) {
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false); // Termina o carregamento
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex flex-col items-center justify-center flex-grow p-4">
        <div className="flex flex-col shadow-xl rounded-lg min-w-[40vw] max-w-[90%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] gap-4 p-4 items-center">
          <div className="flex w-full items-center justify-center">
            <img src={logo} alt="Logo" className="w-[50%]" />
          </div>
          {!emailNotFound && !termsAlreadyAccepted && (
            <div className="flex flex-col mt-4 text-center">
              {language === 'en' ? (
                <div>
                  <h1 className="font-bold text-[#1289A7]">We need your confirmation!</h1>
                  <p>
                    We’ve changed our legal entity and that means the GDPR requires us to get a new confirmation from you that we are allowed to store your data.
                    Don’t worry, everything is still the same: The product and the people behind it! It’s just the company that’s different. It’s like renewing your vows.
                    In order to be able to continue using our services, please click the checkbox and hit 'submit'.
                    Of course, if you have questions, feel free to contact us: <a target="_blank" href="mailto:jenny@intao.io">jenny@intao.io</a>
                  </p>
                </div>
              ) : (
                <div>
                  <h1 className="font-bold text-[#1289A7]">Wir brauchen deine Bestätigung!</h1>
                  <p>
                    Wir haben unsere Unternehmensform geändert, und das bedeutet, dass die DSGVO von uns verlangt, eine neue Bestätigung von Dir einzuholen, dass wir Deine Daten weiterhin speichern dürfen.
                    Keine Sorge, alles bleibt beim Alten: Das Produkt und die Menschen dahinter sind unverändert! Nur das Unternehmen hat sich geändert.
                    Um unsere Dienste weiterhin nutzen zu können, klicke bitte das Kästchen an und sende das Formular ab.
                    Wenn du Fragen hast, kannst Du uns natürlich jederzeit kontaktieren: <a target="_blank" href="mailto:jenny@intao.io">jenny@intao.io</a>
                  </p>
                </div>
              )}
            </div>
          )}

          {emailNotFound && (
            <div className="flex flex-col items-center mt-4">
              <div className="text-center">
                {language === 'en' ? (
                  <div>
                    <h1 className="font-bold text-[#1289A7]">Oops! Something went wrong.</h1>
                    <p>
                      It seems there was an issue with your link. Please contact <a target="_blank" href="mailto:jenny@intao.io" className="text-[#1289A7]">jenny@intao.io</a> to accept the privacy policy and the terms and conditions.
                    </p>
                    <p>Thank you for your understanding!</p>
                  </div>
                ) : (
                  <div>
                    <h1 className="font-bold text-[#1289A7]">Ups! Irgendetwas ist schiefgelaufen.</h1>
                    <p>
                      Es scheint ein Problem mit Deinem Link gegeben zu haben. Bitte wende Dich an <a target="_blank" href="mailto:jenny@intao.io" className="text-[#1289A7]">jenny@intao.io</a>, um die Datenschutzrichtlinie und die Geschäftsbedingungen akzeptieren zu können.
                    </p>
                    <p>Vielen Dank für Dein Verständnis!</p>
                  </div>
                )}
              </div>
            </div>
          )}

          {termsAlreadyAccepted && !emailNotFound && (
            <div className="font-bold text-center mt-4">
              {language === 'en' ? (
                <>You have already accepted the <a target="_blank" href="https://intao.io/en/privacy-policy/" style={{ cursor: "pointer", color: "#1289A7 " }}>Privacy Policy</a> and <a target="_blank" href="https://intao.io/en/terms-and-conditions/" style={{ cursor: "pointer", color: "#1289A7 " }}>Terms and Conditions</a>.</>
              ) : (
                <>Du hast bereits die <a target="_blank" href="https://intao.io/datenschutz/" style={{ cursor: "pointer", color: "#1289A7 " }}>Datenschutzrichtlinie</a> und die <a target="_blank" href="https://intao.io/unsere-geschaftsbedingungen/" style={{ cursor: "pointer", color: "#1289A7 " }}>Allgemeinen Geschäftsbedingungen</a> akzeptiert.</>
              )}
            </div>
          )}

          {!termsAlreadyAccepted && !emailNotFound && (
            <div className="flex items-center mt-6 w-full justify-center">
              <input
                type="checkbox"
                id="terms"
                checked={acceptedTerms}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              <label htmlFor="terms">
                {language === 'en' ? (
                  <>
                    I have read, understood, and accept the <a target="_blank" href="https://intao.io/en/privacy-policy/" style={{ cursor: "pointer", color: "#1289A7 " }}>Privacy Policy</a> and the <a target="_blank" href="https://intao.io/en/terms-and-conditions/" style={{ cursor: "pointer", color: "#1289A7 " }}>Terms and Conditions</a>.
                  </>
                ) : (
                  <>
                    Ich habe die <a target="_blank" href="https://intao.io/datenschutz/" style={{ cursor: "pointer", color: "#1289A7 " }}>Datenschutzrichtlinie</a> und die <a target="_blank" href="https://intao.io/unsere-geschaftsbedingungen/" style={{ cursor: "pointer", color: "#1289A7 " }}>Allgemeinen Geschäftsbedingungen</a> gelesen, verstanden und akzeptiere sie.
                  </>
                )}
              </label>
            </div>
          )}

          {!termsAlreadyAccepted && !emailNotFound && (
            <div>
              <button
                type="submit"
                disabled={!acceptedTerms || isLoading}
                className={`${acceptedTerms ? 'bg-[#EB5C63]' : 'bg-[#d8d8d8]'} text-white font-bold py-2 px-4 rounded mt-4`}
                onClick={handleSubmit}
              >
                {isLoading ? <LoadingComponent /> : language === 'en' ? 'Submit' : 'Absenden'}
              </button>
            </div>
          )}
        </div>
      </main>

      <footer className="flex items-center justify-center w-full shadow-2xl p-4 text-center">
        <p className="text-sm">
          Intao – People Matter<br />
          Intao GmbH, Schrimpfstrasse 32e, 82131 Gauting, Deutschland<br />
          Geschäftsführung: Kathrin Krönig und Ruth Maier-Hamberger<br />
          Handelsregister: Amtsgericht München | HRB 294473
        </p>
      </footer>

      <div className="fixed bottom-4 right-4">
        <button
          onClick={toggleLanguage}
          className="bg-gray-800 text-white font-bold py-2 px-4 rounded"
        >
          {language === 'en' ? 'Deutsch' : 'English'}
        </button>
      </div>
    </div>
  );
}

export default App;
