// eslint-disable-next-line
import React from "react";
// eslint-disable-next-line
import loading from "../../assets/loading.gif";


// eslint-disable-next-line
export const LoadingComponent: React.FC = () => {
    // eslint-disable-next-line
    return(
        // eslint-disable-next-line
        <img src={loading} className="w-[24px]" />
    )
}